import { Controller } from "stimulus"

export default class extends Controller {
  
  load(event) {
    let button = event.currentTarget
    button.disabled = true
    
    fetch(button.dataset.path)
      .then(response => response.text())
      .then(function(html) {
        this.element.innerHTML = html
      }.bind(this))
  }
}
