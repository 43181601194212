import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "results" ]

  search() {
    this.loading()
    fetch(`/dealer_locations?location=${this.inputTarget.value}`, {credentials: "same-origin"})
      .then(response => response.text())
      .then(function(html) {
        this.resultsTarget.innerHTML = html
      }.bind(this))
  }

  handleEnter(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.search()
    }
  }

  loading() {
    this.resultsTarget.innerHTML = ""
  }

}