import Plyr from 'plyr'
import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    const player = new Plyr(this.element)
  }

  // get videoOptions() {
  //   return {
  //     controls: ["play", "pause", "play-large", 'progress', 'fullscreen']
  //   }
  // }

}