import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "form", "businessField" ]

  connect() {
    this.switchForm()
  }

  switchForm() {
    this.businessFieldTargets.forEach(function(field) {
      field.style.display = this.business ? "block" : "none"
    }.bind(this))
  }

  get business() {
    return this.formTarget.elements["customer_account[company_account]"].value == "true"
  }

}