import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    ga('send', {
      hitType: 'event',
      eventCategory: 'Dealeraanvragen',
      eventAction: 'ontvangen'
    })
  }

}