import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    new google.maps.places.Autocomplete(this.element)
  }
  
  
  
}