import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("Exists!")
    console.log(this.element)
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("exists", true, true, null)
    console.log(event)
    this.element.dispatchEvent(event)
  }

}