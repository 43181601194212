import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  chooseDealer(event) {
    let button = event.currentTarget
    this.inputTarget.value = button.dataset.dealerId
  }

}