import Plyr from 'plyr'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "video", "info" ]

  connect() {
    const player = new Plyr(this.videoTarget, this.videoOptions)
    player.on("play", this.hideInfo.bind(this))
    player.on("pause", this.showInfo.bind(this))
  }

  hideInfo() {
    this.element.classList.add("playing")
  }

  showInfo() {
    this.element.classList.remove("playing")
  }

  get videoOptions() {
    return {
      controls: ["play-large", 'progress', 'fullscreen']
    }
  }

}