import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selection", "buscamperSelect", "model"]

  connect() {
    this.allOptions = Array.from(this.buscamperSelectTarget.options)
    this.renderOptions()
  }

  renderOptions() {
    let options = this.allOptions.filter(function(option) {
      return option.dataset.model == this.model || option.value == ""
    }.bind(this))

    this.buscamperSelectTarget.innerHTML = ``
    options.forEach(function(option) {
      this.buscamperSelectTarget.appendChild(option)
    }.bind(this))
  }

  get model() {
    return this.modelTarget.value
  }

}