import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "image", "thumbnail" ]

  view(event) {
    this.thumbnailTargets.forEach(function(thumbnail) {
      thumbnail.classList.remove('active')
    })
    this.imageTarget.src = event.currentTarget.dataset.imageUrl
    event.currentTarget.classList.add('active')
  }

}