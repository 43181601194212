import { Controller } from "stimulus"
import { debounce } from "debounce"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "label", "quantityField" ]

  connect() {
    this.updateLabel()
  }

  submitForm = debounce(function() {
    Rails.fire(this.element, 'submit')
  }, 500)

  updateLabel() {
    this.labelTarget.innerText = this.quantity
  }

  updateQuantity(quantity) {
    this.quantityFieldTarget.value = Math.max(quantity, 0)
    this.updateLabel()
    this.submitForm()
  }

  add() {
    this.updateQuantity(this.quantity + 1)
  }

  subtract() {
    this.updateQuantity(this.quantity - 1)
  }

  get quantity() {
    return parseInt(this.quantityFieldTarget.value)
  }

}