import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "item", "nav" ]

  connect() {
    if (this.current_page_id != undefined) {
      this.highlightLinks()
    }
  }

  openMobileNav() {
    this.navTarget.classList.add("open")
  }

  closeMobileNav() {
    this.navTarget.classList.remove("open")
  }

  highlightLinks() {
    this.itemTargets.forEach((el, index) => {
      el.classList.remove('active', 'current')
      
      if (this.ancestor_ids.includes(el.dataset.pageId))
        el.classList.add('active')

      if (this.current_page_id == el.dataset.pageId) 
        el.classList.add('active', 'current')
    })
  }

  get current_page_id() {
    return document.body.dataset.currentPageId
  }

  get ancestor_ids() {
    return document.body.dataset.ancestorIds.split('-').concat([this.ref_id])
  }

}