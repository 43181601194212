import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "checkoutButton" ]

  addToCart() {
    this.buttonTarget.innerHTML = `<i class="icon icon-check"></i> Toegevoegd aan winkelmand`
    this.buttonTarget.classList.add('added-to-cart')
    this.buttonTarget.disabled = true

    this.checkoutButtonTarget.style.display = "block"
  }

}