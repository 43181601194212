import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    //navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error, this.options)
  }

  success(position) {
    this.inputTarget.value = `${position.coords.latitude},${position.coords.longitude}`
    Rails.fire(this.element, 'submit')
  }

  error(err) {
    console.log(`${err.code}: ${err.message}`)
  }

  options() {
    return {
      enableHighAccurady: true,
      timeout: 5000,
      maximumAge: 0
    }
  }

}