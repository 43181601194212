import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "cableset", "price", "submitButton", "buscamper", "config", "loadingRamp", "busbikerId" ]

  connect() {
    this.toggle()
    this.toggleConfig()
  }

  toggle() {
    // Set field
    this.busbikerIdTarget.value = this.busbikerId || ""
    
    if (this.addToCart) {
      if (this.buscamper.dataset.cableset != "false") {
        this.cablesetTarget.style.display = "block"
      } else {
        this.cablesetTarget.style.display = "none"
      }
      this.priceTargets.forEach(function(price) {
        price.style.display = "inline"
      })
      this.submitButtonTarget.innerText = this.submitButtonTarget.dataset.orderLabel
    } else {
      this.cablesetTarget.style.display = "none"
      this.priceTargets.forEach(function(price) {
        price.style.display = "none"
      })
      this.submitButtonTarget.innerText = this.submitButtonTarget.dataset.dealerLabel
    }
  }

  toggleConfig() {
    if (this.buscamperTarget.value == "") {
      this.configTarget.style.display = "none"
    } else {
      this.configTarget.style.display = "block"
    }
  }

  get addToCart() {
    return this.element.elements["add_to_cart"].value == "true"
  }

  get buscamper() {
    return this.buscamperTarget.options[this.buscamperTarget.selectedIndex]
  }
  
  get busbikerId() {
    return this.buscamper.dataset.busbikerId
  }

}