import Plyr from 'plyr'
import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    const player = new Plyr(this.element, this.videoOptions)
    player.muted = true
    player.volume = 0
  }

  get videoOptions() {
    return {
      autoplay: true,
      clickToPlay: false,
      controls: [],
      keyboardShortcuts: {focused: false, global: false},
      loop: {active: true},
      muted: true,
      volume: 0
    }
  }

}